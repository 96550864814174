// Update these constants as API endpoints are created
const API_ENDPOINTS = {
  // companies routes
  AGREEMENT: (id) => `accounts/agreement/${id}`,
  SIGN_AGREEMENT: () => `accounts/agreement/sign_agreement/`,
  COMPANY: (id = "") => (id ? `company/${id}/` : "company/"), // from HD (doesn't include orderflow data)
  GENERIC_COMPANY: () => "company/search/",
  COMPANY_ARTICLES: (id) => `company/${id}/articles/`,
  COMPANY_LATEST_ORDER_FLOW: () => "company-latest-order-flow/", // from KN
  COMPANY_REPORTED_MARKS_VALUATION: (id) =>
    `company-reported-marks-valuation/${id}`,
  CONTRARY_REPORTS: (id = "") => `company/${id}/contrary-reports/`,
  SACRA_DOCUMENTS: (id = "") => `company/${id}/sacra-documents/`,
  COMPANY_SECTORS: () => `sector/`,
  SEC_FILINGS: () => `secfiling/`,
  COI_FILINGS: () => `coifiling/`,
  STOCK_SPLITS: () => "stocksplit/",
  FUNDING_ROUNDS: () => "fundinground/",
  HD_FUNDING_ROUNDS: () => "company/fundingrounds/",
  ENTITY_PROFILES: () => "entityprofile/",
  HD_ENTITY_PROFILES: () => "entities/entityprofiles",
  CURRENT_PERIOD: () => "orderflow/current-period/",
  INSIGHT_ARTICLE: () => `insightarticle/`,

  // public comps routes
  COMPANY_PRICE_VOLUME_ORDER_FLOW: () => "publiccomps/prices/private/",
  PUBLIC_SECURITY_QUOTE: () => "publiccomps/prices/public/",
  ZX_MARKET_INDEX_VALUES: () =>
    "publiccomps/prices/private-market-index-values/",
  COMPANY_AND_SECURITY_SEARCH: () => "publiccomps/search/",
  PUBLIC_SECURITY_DETAIL: (id) => `publiccomps/security/${id}/`,
  ZX_MARKET_INDEX_DETAIL: (id) => `publiccomps/private-market-index/${id}/`,

  // orderflow
  COMPANY_LATEST_ORDER_FLOW_REPORTS: () =>
    "orderflow/latest-order-flow-reports/",
  COMPANY_VOLUME_TRADING_DATA: (companyId) =>
    `orderflow/volume-trading-data/${companyId}/`,
  COMPANY_TRADING_PRICE_DATA: (companyId) =>
    `orderflow/trading-price-data/${companyId}/`,
  COMPANY_BID_OFFER_RATIO_DATA: (companyId) =>
    `orderflow/bid-offer-ratio-data/${companyId}/`,
  COMPANY_HOLDING_PRICE_DATA: (companyId) =>
    `orderflow/holding-price-data/${companyId}/`,
  COMPANY_RELATIVE_ACTIVITY_DATA: (companyId) =>
    `orderflow/relative-activity-data/${companyId}/`,
  WATCHLIST_COMPANIES: () => "userprefs/watchlist/",
  HEADER_STATS: (companyId) => `orderflow/header-stats/${companyId}/`,
  DOWNLOAD_ZXDATA: (companyId) =>
    `orderflow/download-zxdata-report/${companyId}/`,

  // accounts routes
  LOGIN: () => "accounts/login/",
  REFRESH: () => "accounts/refresh/",
  TOKEN: () => "accounts/token/",
  TOKEN_LOGIN: () => "accounts/login/token/",
  USER: () => "accounts/user/",
  WALKTHROUGH_PROGRESS: (id) => `accounts/walkthrough_progress/${id}/`,
  CHANGE_PASSWORD: (id) => `accounts/change_password/${id}/`,
  RESET_PASSWORD_SEND_EMAIL: () => "accounts/password_reset/",
  RESET_PASSWORD_SET_PASSWORD: () => "accounts/password_reset/confirm/",
  RESET_PASSWORD_NEW_USER: () => "accounts/password_reset/new_user/",
  RESET_PASSWORD_VALIDATE_TOKEN: () =>
    "accounts/password_reset/validate_token/",
  CHANGE_ACCOUNT_SETTINGS: () => "accounts/update_account_settings/",
  EMAIL_CHANGE_REQUEST: () => "accounts/change_email/",
  EMAIL_CHANGE_CONFIRM: () => "accounts/change_email/confirm/",
  UPDATED_AGREEMENT_FILES: () => "accounts/updated_agreement_files/",

  // access routes
  HAS_FEATURE_ACCESS: () => `access/has-feature-access/`,
  HAS_PRO_DATA_ACCESS: (companyId) =>
    `access/has-pro-data-access/${companyId}/`,
  CAN_DOWNLOAD_ZX_DATA_REPORT: (company_id) =>
    `access/can-download-zx-data-report/${company_id}`,

  // analytics routes
  REPORT_ERROR: () => "analytics/report-error/",
  MIXPANEL_TRACK: () => "analytics/mixpanel/track/",
  MIXPANEL_SET_PEOPLE: () => "analytics/mixpanel/set-people/",

  PORTFOLIOS: () => "portfolios/",
  PORTFOLIO_DETAIL: (id) => `portfolios/${id}/`,
  DOWNLOAD_PORTFOLIO: (id) => `portfolios/${id}/download/`,
  PORTFOLIO_COMPANIES: () => `portfolios/companies/`,
  SHARE_LOTS: () => `portfolios/share-lots/`,
  SHARE_LOT_DETAIL: (id) => `portfolios/share-lots/${id}/`,

  // custom valuations routes
  CUSTOM_VALUATIONS: () => "valuations/",

  // transaction record aggregates
  TRANSACTION_RECORD_AGGREGATES: () => "transaction-records/",
};

// KN pagination query param
const KN_PAGE_QUERY_PARAM = "page[number]";

// pagination refetch buffer
const PAGINATION_ROW_COUNT_BUFFER = 20;

// feature names
const SACRA_FEATURE_NAME = "Sacra Documents";
const PRIVATE_MARKET_INDICES_FEATURE_NAME = "ZX Private Market Indices";
const EV_CALCULATOR_FEATURE_NAME = "EV Calculator";
const COMPANY_TRANSFERABILITY_FEATURE_NAME = "Company Transferability";
const CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME = "Custom Valuation Calculator";
const PORTFOLIOS_FEATURE_NAME = "Portfolios";
const HOME_PAGE_FEATURE_NAME = "Home Page";
const CLOSED_TRANSACTIONS_FEATURE_NAME = "Closed transactions";

export {
  API_ENDPOINTS,
  KN_PAGE_QUERY_PARAM,
  PAGINATION_ROW_COUNT_BUFFER,
  SACRA_FEATURE_NAME,
  PRIVATE_MARKET_INDICES_FEATURE_NAME,
  EV_CALCULATOR_FEATURE_NAME,
  COMPANY_TRANSFERABILITY_FEATURE_NAME,
  CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME,
  PORTFOLIOS_FEATURE_NAME,
  HOME_PAGE_FEATURE_NAME,
  CLOSED_TRANSACTIONS_FEATURE_NAME,
};
