import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  ButtonStyles,
  ColorPalette,
  DotsVerticalIcon,
  DownloadIcon,
  Fonts,
  IconButton,
  IconButtonStyles,
  PlusIcon,
  Thumbnail,
  useAnchoredMenu,
  useDropdown,
  YukaColorPalette,
  Z_INDEXES,
  BookmarkIcon,
  BookmarkFilledIcon,
  YukaThemeProvider,
  useTooltip,
  LinkStyles,
  LinkTypes,
  HyperLink,
} from "yuka";

import AddToPortfolioDropdown from "./AddToPortfolioDropdown";
import { useCompany, useLatestOrderflowForCompany } from "./hooks";
import useCompanyAccess from "./hooks/useCompanyAccess";
import { borderStyle } from "./StyledComponents";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import useFetch from "../api/useFetch";
import useWrite from "../api/useWrite";
import { PAGE_WIDTH } from "../constants";
import { DataverseColors } from "../hdYuka/constants";
import CreatePortfolioModal from "../Portfolios/CreatePortfolioModal";
import RobustnessScoreLabel from "../RobustnessScoreLabel";
import { ACTIONS, useDispatch } from "../routes/StateProvider";
import {
  MIXPANEL_SOURCE_COMPANY_PROFILE,
  MIXPANEL_SOURCE_COMPANY_PROFILE_FLOATING_HEADER,
} from "../utils/constants";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import RobustnessScoreModal from "./RobustnessScoreModal";
import ZXIndexValueModal from "./ZXIndexValueModal";

const FloatingHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: ${YukaColorPalette.surface1};
  z-index: ${Z_INDEXES.zIndexExtraVisible};
  width: 100%;
  transition: transform 0.3s ease-in-out;
  border-top: 1px solid ${ColorPalette.white15};
  border-bottom: 1px solid ${ColorPalette.white15};
  ${(props) =>
    props.$open
      ? css`
          transform: translateY(16px);
        `
      : css`
          transform: translateY(-100%);
        `}
`;

const FloatingDropdownAttachmentDiv = styled.div`
  transform: translateY(-52px);
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 12px;
`;

const FloatingHeaderContent = styled.div`
  ${PAGE_WIDTH}
  height: 64px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  > :first-child {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
  }

  > :last-child {
    display: flex;
    align-items: center;
  }
`;

const StyledCompanyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledHorizontalRule = styled.hr`
  border: none;
  border-bottom: ${borderStyle};
`;

const StyledName = styled.div`
  display: flex;
  height: 46px;
  justify-content: space-between;

  > :first-child {
    display: flex;
    gap: 16px;
  }

  > :last-child {
    display: flex;
    align-items: center;
  }
`;

const StyledPricingInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StyledFont40 = styled.span`
  font-size: 40px;
`;

const StyledFont30 = styled.span`
  font-size: 30px;
`;

const StyledIndexValueCaption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledThumbnail = styled(Thumbnail)`
  border-radius: 12px;
`;

const MIXPANEL_SOURCE = "company profile";

const StyledCompanyName = styled(Fonts.Headline3theme80).attrs({ as: "div" })``;
const StyledLegalName = styled(Fonts.Body1theme30).attrs({ as: "div" })``;

const ZX_INDEX_VALUE_MODAL = "ZX_INDEX_VALUE_MODAL";
const ROBUSTNESS_MODAL = "ROBUSTNESS_MODAL";
const CREATE_PORTFOLIO_MODAL = "CREATE_PORTFOLIO_MODAL";

const ZX_INDEX_VALUE_TOOLTIP_TEXT =
  "A custom metric derived from our proprietary algorithm blending transacted prices with open bids and offers.";
const ROBUSTNESS_SCORE_TOOLTIP_TEXT =
  "A score ranging from 1-10 to objectively assess the confidence levels in the ZX Index Value for different companies. Key metrics that make up the Robustness Score include recency of orders and total volume.";

/**
 * Company profile header has 2 modes, floating and not floating. The floating version has
 * less information and spans the full page. It should only appear after the user has scrolled
 * beyond the point in the page that the non-floating header is no longer visible.
 *
 * @param floating
 * @param hidden
 * @return {JSX.Element}
 * @constructor
 */
const CompanyProfileHeader = ({ floating, hidden }) => {
  const dispatch = useDispatch();
  const mixpanelSource =
    floating && !hidden
      ? MIXPANEL_SOURCE_COMPANY_PROFILE_FLOATING_HEADER
      : MIXPANEL_SOURCE_COMPANY_PROFILE;
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalShowing, setModalShowing] = useState(null);
  const [company, companyIsLoading] = useCompany();
  const [latestOrderFlow, latestOrderFlowLoading] =
    useLatestOrderflowForCompany(company?.zb_id);

  const zxIndexValueRef = React.useRef(null);
  const zxIndexValueTooltip = useTooltip(
    zxIndexValueRef,
    <React.Fragment>
      <b>ZX Index Value</b>
      <p>
        {ZX_INDEX_VALUE_TOOLTIP_TEXT}
        <HyperLink
          linkStyle={LinkStyles.INVISIBLE}
          linkType={LinkTypes.BUTTON}
          onClick={() => setModalShowing(ZX_INDEX_VALUE_MODAL)}
        >
          &nbsp;<u>Click to learn more.</u>
        </HyperLink>
      </p>
    </React.Fragment>
  );

  const robustnessScoreRef = React.useRef(null);
  const robustnessScoreTooltip = useTooltip(
    robustnessScoreRef,
    <React.Fragment>
      <b>Robustness Score</b>
      <p>
        {ROBUSTNESS_SCORE_TOOLTIP_TEXT}
        <HyperLink
          linkStyle={LinkStyles.INVISIBLE}
          linkType={LinkTypes.BUTTON}
          onClick={() => setModalShowing(ROBUSTNESS_MODAL)}
        >
          &nbsp;<u>Click to learn more.</u>
        </HyperLink>
      </p>
    </React.Fragment>
  );

  const showingZXIndexValueTooltip = Boolean(zxIndexValueTooltip);

  useEffect(() => {
    if (showingZXIndexValueTooltip) {
      MixpanelEvents.openCompanyProfileTooltip(
        mixpanelSource,
        "zx index value"
      );
    }
  }, [mixpanelSource, showingZXIndexValueTooltip]);

  const showingRobustness = Boolean(robustnessScoreTooltip);

  useEffect(() => {
    if (showingRobustness) {
      MixpanelEvents.openCompanyProfileTooltip(
        mixpanelSource,
        "robustness score"
      );
    }
  }, [mixpanelSource, showingRobustness]);

  const { hasAnyAccess, canDownloadZXData, timeFrame } = useCompanyAccess(
    company?.zb_id
  );

  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES(), {
    silent: true,
  });
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES(), false, {
    silent: true,
  });

  const isWatchlisted = useMemo(
    () =>
      watchlistQueryInfo.isSuccess && !companyIsLoading
        ? _.filter(watchlistQueryInfo.cleanedData, { company: company?.zb_id })
            .length
        : false,
    [watchlistQueryInfo, company, companyIsLoading]
  );

  const zxIndexValueDisplay = useMemo(() => {
    let FontComponent = floating ? React.Fragment : StyledFont30;
    if (latestOrderFlowLoading) {
      return (
        <Fonts.Headline1theme50>
          <FontComponent>Loading...</FontComponent>
        </Fonts.Headline1theme50>
      );
    }
    if (!latestOrderFlow || _.isNil(latestOrderFlow[0]?.zx_index_price)) {
      return (
        <Fonts.Headline1theme50>
          <FontComponent>
            This week's ZX Index Value not available
          </FontComponent>
        </Fonts.Headline1theme50>
      );
    }
    FontComponent = floating ? React.Fragment : StyledFont40;
    return (
      <Fonts.Headline1theme80 ref={zxIndexValueRef}>
        <FontComponent>
          {expandedMoneyFormat(latestOrderFlow[0]?.zx_index_price, 2, 2)}
        </FontComponent>
      </Fonts.Headline1theme80>
    );
  }, [latestOrderFlowLoading, latestOrderFlow, floating]);

  const previousValueDisplay = useMemo(() => {
    if (latestOrderFlowLoading || !latestOrderFlow) {
      return null;
    }
    const [currentOrderFlow, previousOrderFlow] = latestOrderFlow;
    if (
      _.isNil(currentOrderFlow?.zx_index_price) ||
      _.isNil(previousOrderFlow?.zx_index_price) ||
      _.isNil(currentOrderFlow.zx_index_value_percent_change)
    ) {
      return null;
    }
    const percentageChange = Number(
      currentOrderFlow.zx_index_value_percent_change
    );
    const difference =
      Number(currentOrderFlow.zx_index_price) -
      Number(previousOrderFlow.zx_index_price);

    const FontComponent = floating ? React.Fragment : StyledFont30;

    if (percentageChange > 0) {
      return (
        <Fonts.Headline1buy>
          <FontComponent>
            +{expandedMoneyFormat(difference, 2, 2)}
            (+{percentFormat(percentageChange * 100)})
          </FontComponent>
        </Fonts.Headline1buy>
      );
    }
    if (percentageChange < 0) {
      return (
        <Fonts.Headline1sell>
          <FontComponent>
            -{expandedMoneyFormat(Math.abs(difference), 2, 2)}(
            {percentFormat(percentageChange * 100)})
          </FontComponent>
        </Fonts.Headline1sell>
      );
    }
    return (
      <Fonts.Headline1theme80>
        <FontComponent>
          +{expandedMoneyFormat(difference, 2, 2)}
          (+{percentFormat(percentageChange * 100)})
        </FontComponent>
      </Fonts.Headline1theme80>
    );
  }, [latestOrderFlow, latestOrderFlowLoading, floating]);

  const reportPeriodDisplay = useMemo(() => {
    if (
      latestOrderFlowLoading ||
      !latestOrderFlow ||
      !latestOrderFlow[0]?.report_period ||
      _.isNil(latestOrderFlow[0]?.zx_index_price)
    ) {
      return null;
    }
    return (
      <Fonts.Body2theme50>
        ZX Index Value as of{" "}
        {DateTime.fromISO(latestOrderFlow[0].report_period).toLocaleString()}
      </Fonts.Body2theme50>
    );
  }, [latestOrderFlowLoading, latestOrderFlow]);

  const robustnessDisplay = useMemo(() => {
    if (
      latestOrderFlowLoading ||
      !latestOrderFlow ||
      _.isNil(latestOrderFlow[0]?.robustness) ||
      _.isNil(latestOrderFlow[0]?.zx_index_price)
    ) {
      return null;
    }
    return (
      <RobustnessScoreLabel
        ref={robustnessScoreRef}
        score={latestOrderFlow[0]?.robustness}
      />
    );
  }, [latestOrderFlowLoading, latestOrderFlow]);

  const [addToPortfolioDropdown, dropdownRef, toggleAddToPortfolioDropdown] =
    useDropdown(() => (
      <AddToPortfolioDropdown
        source={mixpanelSource}
        openCreatePortfolioModal={() => setModalShowing(CREATE_PORTFOLIO_MODAL)}
      />
    ));

  const [actionsMenu, menuRef, toggleAdditionalActionsMenu] = useAnchoredMenu({
    menuItems: [
      {
        key: "watchlist",
        text: isWatchlisted ? "Remove from Watchlist" : "Add to Watchlist",
        iconColor: isWatchlisted
          ? DataverseColors.branding500
          : ColorPalette.white50,
        icon: isWatchlisted ? BookmarkFilledIcon : BookmarkIcon,
        onClick: () => {
          if (isWatchlisted) {
            MixpanelEvents.removeCompanyFromWatchlist(
              mixpanelSource,
              company?.name
            );
            watchlistDelete.mutate({ id: company?.zb_id });
          } else {
            MixpanelEvents.addCompanyToWatchlist(mixpanelSource, company?.name);
            watchlistUpdate.mutate({ company: company?.zb_id });
          }
        },
      },
      canDownloadZXData
        ? {
            key: "download",
            text: "Download ZXData (XLS)",
            icon: DownloadIcon,
            onClick: () => {
              setIsDownloading(true);
              MixpanelEvents.downloadXlsSpreadsheet(mixpanelSource);
              AxiosInstance.get(API_ENDPOINTS.DOWNLOAD_ZXDATA(company?.zb_id), {
                params: {
                  time_frame: timeFrame,
                },
                responseType: "blob",
              })
                .then(() => {
                  dispatch({
                    type: ACTIONS.addToast,
                    message:
                      "Your ZXData Report is being generated, please check your email in a couple of minutes for receipt of the report.",
                  });
                })
                .catch(() => {
                  dispatch({
                    type: ACTIONS.addToast,
                    message:
                      "Failed to download ZXData Report. Please try again or contact support.",
                  });
                })
                .finally(() => {
                  setIsDownloading(false);
                });
            },
          }
        : null,
    ].filter(Boolean),
  });

  useEffect(() => {
    // Just a bit of cleanup to automatically close the dropdown if the user scrolls into a region
    // of the screen where a floating header is no longer showing.
    if (floating && hidden && addToPortfolioDropdown) {
      toggleAddToPortfolioDropdown();
    }
    if (floating && hidden && actionsMenu) {
      toggleAdditionalActionsMenu();
    }
  }, [
    floating,
    hidden,
    addToPortfolioDropdown,
    toggleAddToPortfolioDropdown,
    actionsMenu,
    toggleAdditionalActionsMenu,
  ]);

  if (floating) {
    return (
      <FloatingHeaderContainer $open={!hidden}>
        <FloatingHeaderContent>
          <div>
            <StyledThumbnail src={company.main_picture} size="40px" />
            <Fonts.Headline2theme80>{company?.name}</Fonts.Headline2theme80>
            {zxIndexValueDisplay}
            {previousValueDisplay}
            {robustnessDisplay}
            {zxIndexValueTooltip}
            {robustnessScoreTooltip}
          </div>
          {hasAnyAccess && (
            <div>
              <Button
                onClick={toggleAddToPortfolioDropdown}
                buttonStyle={ButtonStyles.RAISED}
                leadingIcon={PlusIcon}
              >
                Add to portfolio
              </Button>
              <FloatingDropdownAttachmentDiv ref={dropdownRef} />
              <StyledIconButton
                onClick={toggleAdditionalActionsMenu}
                buttonStyle={IconButtonStyles.RAISED}
                icon={DotsVerticalIcon}
              />
              <FloatingDropdownAttachmentDiv ref={menuRef} />
            </div>
          )}
        </FloatingHeaderContent>
        {hasAnyAccess && (
          <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
            {addToPortfolioDropdown}
            {actionsMenu}
          </YukaThemeProvider>
        )}
        {modalShowing === CREATE_PORTFOLIO_MODAL && (
          <CreatePortfolioModal
            source={MIXPANEL_SOURCE}
            navigateOnSuccess={false}
            onClose={() => setModalShowing(null)}
          />
        )}
        {modalShowing === ROBUSTNESS_MODAL && (
          <RobustnessScoreModal
            source={MIXPANEL_SOURCE}
            onClose={() => setModalShowing(null)}
          />
        )}
        {modalShowing === ZX_INDEX_VALUE_MODAL && (
          <ZXIndexValueModal
            source={MIXPANEL_SOURCE}
            onClose={() => setModalShowing(null)}
          />
        )}
        {isDownloading && <LoadingSpinner />}
      </FloatingHeaderContainer>
    );
  }

  return (
    <StyledCompanyHeader>
      <StyledName>
        <div>
          <StyledThumbnail src={company.main_picture} size="46px" />
          <div>
            <StyledCompanyName>{company.name}</StyledCompanyName>
            <StyledLegalName>{company.legal_name}</StyledLegalName>
          </div>
        </div>
        {hasAnyAccess && (
          <div>
            <Button
              id="add-company-to-portfolio-button"
              ref={dropdownRef}
              onClick={toggleAddToPortfolioDropdown}
              buttonStyle={ButtonStyles.RAISED}
              leadingIcon={PlusIcon}
            >
              Add to portfolio
            </Button>
            <IconButton
              ref={menuRef}
              onClick={toggleAdditionalActionsMenu}
              buttonStyle={IconButtonStyles.RAISED}
              icon={DotsVerticalIcon}
            />
          </div>
        )}
      </StyledName>
      <StyledPricingInformation>
        <div>
          {zxIndexValueDisplay}
          {previousValueDisplay}
        </div>
        <StyledIndexValueCaption>
          {reportPeriodDisplay}
          {Boolean(reportPeriodDisplay) && Boolean(robustnessDisplay) && (
            <Fonts.Body2theme30>|</Fonts.Body2theme30>
          )}
          {robustnessDisplay}
          {zxIndexValueTooltip}
          {robustnessScoreTooltip}
        </StyledIndexValueCaption>
      </StyledPricingInformation>
      <StyledHorizontalRule />
      {hasAnyAccess && (
        <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
          {addToPortfolioDropdown}
          {actionsMenu}
        </YukaThemeProvider>
      )}
      {modalShowing === CREATE_PORTFOLIO_MODAL && (
        <CreatePortfolioModal
          source={MIXPANEL_SOURCE}
          navigateOnSuccess={false}
          company={company}
          onClose={() => setModalShowing(null)}
        />
      )}
      {modalShowing === ROBUSTNESS_MODAL && (
        <RobustnessScoreModal
          source={MIXPANEL_SOURCE}
          onClose={() => setModalShowing(null)}
        />
      )}
      {modalShowing === ZX_INDEX_VALUE_MODAL && (
        <ZXIndexValueModal
          source={MIXPANEL_SOURCE}
          onClose={() => setModalShowing(null)}
        />
      )}
      {isDownloading && <LoadingSpinner />}
    </StyledCompanyHeader>
  );
};

CompanyProfileHeader.propTypes = {
  floating: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default CompanyProfileHeader;
