import PropTypes from "prop-types";
import { useEffect } from "react";
import styled from "styled-components";
import {
  Fonts,
  body1,
  Modal,
  ModalStyles,
  ButtonStyles,
  Button,
  YukaColorPalette,
} from "yuka";

import {
  StyledModalButtonRow,
  StyledModalContent as BaseStyledModalContent,
} from "../Portfolios/StyledComponents";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledHeader = styled(Fonts.Headline2theme80).attrs({ as: "div" })`
  background: ${YukaColorPalette.surface1};
  padding: 24px;
`;

const StyledModalContent = styled(BaseStyledModalContent)`
  background: ${YukaColorPalette.surface2};
  gap: 8px;
`;

const StyledModal = styled(Modal)`
  width: 800px;
  ${body1};
  line-height: 1.5;

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: disc inside;
  }

  ol,
  ul {
    margin: 16px 0;
    padding: 0;

    li {
      padding-left: 16px;
    }
  }
`;

const StyledParagraph = styled(Fonts.Body2theme80)`
  line-height: 1.5;
`;

const RobustnessScoreModal = ({ onClose, source }) => {
  useEffect(() => {
    MixpanelEvents.openRobustnessScoreModal(source);
  }, [source]);

  return (
    <StyledModal onClose={onClose} modalStyle={ModalStyles.MINIMAL}>
      <StyledHeader>Robustness Score</StyledHeader>
      <StyledModalContent $maxHeight={400}>
        <StyledParagraph>
          The ZXData Robustness Score is a proprietary algorithm developed by
          Zanbato to objectively assess the confidence levels in the ZX Index
          Value for different private companies. This score is calculated on a
          scale of 1 to 10 and is based on several key metrics, which provide an
          indication of the reliability of the price signals for a given
          company. These metrics include:
          <ol>
            <li>
              Number of executed trades and buy and sell orders: Companies with
              a higher number of executed trades and submitted orders tend to
              receive a higher Robustness Score. This suggests that more trading
              activity and order flow contribute to a greater level of
              confidence in the company's secondary trading price data.
            </li>
            <li>
              Age of the closed transactions and buy and sell orders: The age of
              the transactions and orders is also taken into account. More
              recent transactions and orders may contribute to a higher score,
              as they reflect more up-to-date market activity.
            </li>
            <li>
              Total volume of closed transactions and buy and sell orders:
              Greater volume of trading activity typically leads to a higher
              Robustness Score, as it indicates greater market participation and
              liquidity.
            </li>
            <li>
              The spread between bid and offer price: A narrower spread between
              the bid and offer price may contribute to a higher score, as it
              suggests a more efficient and liquid market.
            </li>
            <li>
              Symmetry of the orderbook: Companies with more balanced and
              two-sided order books are generally awarded higher Robustness
              Scores. Conversely, companies with order books that are skewed
              toward one side (either bids or offers) are considered less
              reliable in providing price signals.
            </li>
          </ol>
          <span>Here's how the scores are typically interpreted:</span>
          <ul>
            <li>
              Score of 2 and below: Companies with fewer than 3 orders on either
              side of the market.
            </li>
            <li>
              Score of 3-4: Companies with 3-5 orders on each side of the market
              or a more asymmetric orderbook with 5+ orders. Most companies with
              scores of 3 or 4 do not have recently executed trades in the data
              set.
            </li>
            <li>
              Score of 5: Typically indicates at least one executed trade or a
              more balanced orderbook, but with no recently executed trades.
            </li>
            <li>
              Score of 6 or greater: Usually includes at least one executed
              trade in the data set, with some edge cases reflecting a deep
              orderbook of 10+ orders on both sides of the market.
            </li>
            <li>
              Score of 7 and above: Reflect deeper order books as well as
              multiple executed trades, indicating a higher level of confidence
              in the price data.
            </li>
          </ul>
        </StyledParagraph>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
          Close
        </Button>
      </StyledModalButtonRow>
    </StyledModal>
  );
};

RobustnessScoreModal.propTypes = {
  source: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RobustnessScoreModal;
