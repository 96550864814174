import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Fonts, FontWeights } from "yuka";

const StyledCounterContainer = styled(Fonts.Caption2theme80).attrs({
  as: "div",
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 16px;
  padding: 0 8px;
  ${(props) => css`
    background: ${props.$background};
    color: ${props.$color};
  `}
`;

const StyledCounter = styled.span`
  ${FontWeights.bold}
  padding: 0;
`;

/*
 * Handy label to include as the trailing text in the zx index price fields to indicate
 * the robustness score of the provided default value.
 */
const RobustnessScoreLabel = React.forwardRef(({ score }, ref) => {
  const colorOptions = {
    low: {
      background: "#484846",
      color: "#918F8C",
    },
    medium: {
      background: "#555342",
      color: "#C4BC71",
    },
    high: {
      background: "#263322",
      color: "#83B59C",
    },
  };
  let color = colorOptions.low;
  if (score >= 3) {
    color = colorOptions.medium;
  }
  if (score >= 7) {
    color = colorOptions.high;
  }
  return (
    <StyledCounterContainer
      ref={ref}
      $background={color.background}
      $color={color.color}
    >
      RS<StyledCounter>{score}</StyledCounter>
    </StyledCounterContainer>
  );
});

RobustnessScoreLabel.propTypes = {
  score: PropTypes.number.isRequired,
};

export default RobustnessScoreLabel;
