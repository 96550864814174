import PropTypes from "prop-types";
import { useEffect } from "react";
import { Fonts, Button, ButtonStyles, Modal, ModalStyles } from "yuka";

import {
  StyledModalButtonRow,
  StyledModalContent,
} from "../Portfolios/StyledComponents";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const ZXIndexValueModal = ({ onClose, source }) => {
  useEffect(() => {
    MixpanelEvents.openZXIndexValueModal(source);
  }, [source]);

  return (
    <Modal
      modalStyle={ModalStyles.MINIMAL}
      title="ZX Index Value"
      onClose={onClose}
      cancelText="Close"
    >
      <StyledModalContent $width={800}>
        <Fonts.Headline2theme80>ZX Index Value</Fonts.Headline2theme80>
        <p>
          ZX Index Value is an internally developed metric that reflects
          secondary trading prices for private companies using an algorithmic
          approach based on the prices sourced from ZX and ZXData partners. The
          algorithm used to calculate ZX Index Values incorporates both executed
          transaction prices and current bid and ask pricing. As such, ZX Index
          Value provides a better representation of current market conditions
          versus closed prices given there is usually a 30–45-day lag between
          the time that a transaction gets matched and the time when it is fully
          executed.
        </p>
        <p>
          The algorithm is developed by incorporating metrics such as executed
          prices, inside market prices, and size of transactions. Executed
          transactions are consistently given the highest weighting in the ZX
          Index Value, followed by non-executed prices. Inside market prices see
          higher weightings than bids and offers further down the orderbook, as
          they consider current market conditions. Institutionally sized
          transactions are weighted above smaller transactions in the ZX Index
          Value. In addition, half-lives are calculated for all ZX Index Value
          inputs. Empirically, time decays typically see a substantial drop-off
          after two weeks.
        </p>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button onClick={onClose} buttonStyle={ButtonStyles.RAISED}>
          Close
        </Button>
      </StyledModalButtonRow>
    </Modal>
  );
};

ZXIndexValueModal.propTypes = {
  source: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ZXIndexValueModal;
